
































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const UserModule = namespace('UserModule');

@Component
export default class UserEdit extends Vue {
  @Prop({required: true}) user!: any;
  private show: boolean = false;
  private loading: boolean = false;

  private firstname = this.user.firstname;
  private lastname = this.user.lastname;
  private email = this.user.email;
  private roles = this.user.roles;
  private verified = this.user.verifiedAt !== null;

  @UserModule.Action
  private updateUser!: (user: any) => Promise<void>;

  async saveUser(): Promise<void> {
    this.loading = true;
    console.log(`Update user ${this.user.id}`)
    
    // verified updated
    let verifiedAt = undefined;
    if((this.user.verifiedAt !== null) !== this.verified) {
      verifiedAt = this.verified ? new Date() : null;
    }

    await this.updateUser({
      id: this.user.id,
      firstname: this.firstname,
      lastname: this.lastname,
      roles: this.roles,
      email: this.email,
      verifiedAt
    });
    this.loading = false;
    this.show = false;
  }

}
