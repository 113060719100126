

















































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import UserDelete from '@/components/UserAdministration/UserDelete.vue';
import UserEdit from '@/components/UserAdministration/UserEdit.vue';
const UserModule = namespace("UserModule");

@Component({
  components: { UserDelete, UserEdit } 
})
export default class UserAdministration extends Vue {

  get headers(){
    return [
      {
        text: 'Firstname',
        value: 'firstname'
      },
      {
        text: 'Lastname',
        value: 'lastname'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Roles',
        value: 'roles'
      },
      {
        text: 'Actions',
        value: 'actions'
      }
    ]
  }

  private loading: boolean = false;
  private search: string = '';

  @UserModule.State('users')
  private users!: any;

  @UserModule.Action
  private loadUsers!: () => Promise<void>;

  private async reloadUsers() {
    this.loading = true;
    await this.loadUsers();
    this.loading = false;
  }

  async mounted(): Promise<void> {
    await this.reloadUsers();
  }

}
