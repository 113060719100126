



































import { Component, Vue, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
const UserModule = namespace('UserModule');

@Component
export default class UserDelete extends Vue {
  @Prop({required: true}) readonly userId!: string;
  @Prop({required: true}) readonly userName!: string;
  private show: boolean = false;
  private loading: boolean = false;

  @UserModule.Action
  private deleteUser!: (id: string) => Promise<void>;

  async removeUser(): Promise<void> {
    this.loading = true;
    console.log(`Deleteing userId ${this.userId}`)
    await this.deleteUser(this.userId);
    this.loading = false;
    this.show = false;
  }

}
